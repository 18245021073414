import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants/userEvents";

import OnboardingErrorLog from "../../../types/onboarding/OnboardingErrorLog";

function logOnboardingError(
  stage: string,
  message: string,
  hcpId?: string,
  eventMeta?: Record<string, string | undefined>
) {
  const payload: OnboardingErrorLog = {
    title: `Signup API failed to complete ${stage} stage`,
    details: {
      error: message,
    },
    logArea: "SERVER",
    logType: USER_EVENTS.ONBOARDING_ERROR,
    featureName: "SIGNUP",
  };

  if (hcpId) {
    payload["hcpId"] = hcpId;
  }

  if (eventMeta) {
    Object.assign(payload.details, eventMeta);
  }

  deprecatedDoNotUseLogError({
    message: JSON.stringify(payload),
  });
}

export { logOnboardingError };
