import { Worker } from "@src/appV2/Worker/api/types";

import { OnboardingRouterPath } from "../routing/constant/onboardingRoute";

export const STAGES = {
  agentSignUpInfo1: {
    path: OnboardingRouterPath.ONBOARDING_INFO1,
    nextStagePath: OnboardingRouterPath.ONBOARDING_EMAIL,
  },
  agentEmail: {
    path: OnboardingRouterPath.ONBOARDING_EMAIL,
    nextStagePath: OnboardingRouterPath.ONBOARDING_FIRST_NAME,
  },
  agentFirstName: {
    path: OnboardingRouterPath.ONBOARDING_FIRST_NAME,
    nextStagePath: OnboardingRouterPath.ONBOARDING_LAST_NAME,
  },
  agentLastName: {
    path: OnboardingRouterPath.ONBOARDING_LAST_NAME,
    nextStagePath: OnboardingRouterPath.ONBOARDING_ADDRESS,
  },
  agentAddress: {
    path: OnboardingRouterPath.ONBOARDING_ADDRESS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentDOB: {
    path: OnboardingRouterPath.ONBOARDING_DOB,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentSSN: {
    path: OnboardingRouterPath.ONBOARDING_SSN,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentSignUpInfo2: {
    path: OnboardingRouterPath.ONBOARDING_INFO2,
    nextStagePath: OnboardingRouterPath.ONBOARDING_LICENSE,
  },
  agentQualification: {
    path: OnboardingRouterPath.ONBOARDING_QUALIFICATION,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO3,
  },
  agentLicense: {
    path: OnboardingRouterPath.ONBOARDING_QUALIFICATION,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO3,
  },
  agentSignUpInfo3: {
    path: OnboardingRouterPath.ONBOARDING_INFO3,
    nextStagePath: OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS,
  },
  agentPotentialShifts: {
    path: OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW,
  },
  contractorAgreementReview: {
    path: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW,
    nextStagePath: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT,
  },
  contractorAgreement: {
    path: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT,
    nextStagePath: OnboardingRouterPath.ONBOARDING_STRIPE,
  },
  stripeOnboarding: {
    path: OnboardingRouterPath.ONBOARDING_STRIPE,
    nextStagePath: OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE,
  },
  agentReferralSubmission: {
    path: OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE,
    nextStagePath: OnboardingRouterPath.READY_TO_EARN,
  },
  agentReadyToEarn: {
    path: OnboardingRouterPath.READY_TO_EARN,
    nextStagePath: OnboardingRouterPath.SHOW_FRIENDS_SHIFTS,
  },
  agentShowFriendsShifts: {
    path: OnboardingRouterPath.SHOW_FRIENDS_SHIFTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO,
  },
  proceedToRequirementsInfo: {
    path: OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO,
    nextStagePath: OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS,
  },
  uploadDocuments: {
    path: OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_WELCOME,
  },
};

export interface OnBoardingRouteProps {
  agent?: Worker;
}
