import { OIG } from "../constants/OIG_VALIDATION";

export type OigResponse = {
  validationId: string;
  requestFurtherValidation: boolean;
};

type OigState = {
  validationId: string;
  dobRequired?: boolean;
  ssnRequired?: boolean;
};

export function getOigState(): OigState | null {
  const oig = localStorage.getItem(OIG);
  if (oig) {
    try {
      const oigResponse = JSON.parse(oig) as OigState;
      return oigResponse;
    } catch (error) {
      return null;
    }
  }
  return null;
}
