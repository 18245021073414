export function extractMarkdownLink(text: string): string | undefined {
  const regex = /\[([^\]]*)\]\(([^()]+(?:\([^()]*\))?[^()]*)\)/;
  const match = text.match(regex);

  if (match && match[2]) {
    return match[2];
  }

  return undefined;
}
