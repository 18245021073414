const workerMobilePrefix = "Worker Mobile:";

const ONBOARDING_SEGMENT_EVENT_NAMES = {
  CONTINUED_SIGNUP: `${workerMobilePrefix} Signup Continued`,
  RESTARTED_SIGNUP: `${workerMobilePrefix} Onboarding - Signup Restarted`,
  ENTERED_FIRST_NAME: `${workerMobilePrefix} Onboarding - First Name Entered`,
  ENTERED_LAST_NAME: `${workerMobilePrefix} Onboarding - Last Name Entered`,
  ENTERED_EMAIL: `${workerMobilePrefix} Onboarding - Email Entered`,
  ENTERED_ADDRESS: `${workerMobilePrefix} Onboarding - Address Entered`,
  PROCEEDED_TO_PROFESSIONAL_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Professional Info`,
  ENTERED_LICENSE_INFORMATION: `${workerMobilePrefix} Onboarding - License Info Entered`,
  PROCEEDED_TO_PAY_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Pay Info`,
  VIEWED_CONTRACTOR_BENEFITS: `${workerMobilePrefix} Onboarding - Contractor Benefits Viewed`,
  PROCEEDED_TO_REQUIREMENTS_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Requirements Info`,
  ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP: `${workerMobilePrefix} Onboarding - Document Add Attempted`,
  ADDED_DOCUMENT_DURING_SIGNUP: `${workerMobilePrefix} Onboarding - Document Added`,
  FINISHED_SUBMITTING_DOCUMENTS: `${workerMobilePrefix} Submitting Documents Finished`,
  ENTERED_APP: `${workerMobilePrefix} App Entered`,
  ENTERED_SSN: `${workerMobilePrefix} Onboarding - SSN Entered`,
  SELECTED_EXAMPLE_SHIFT: `${workerMobilePrefix} Example Shifts Selected`,
  ENTERED_DOB: `${workerMobilePrefix} Onboarding - DoB Entered`,
  ENTERED_SEE_FRIENDS_SHIFTS: `${workerMobilePrefix} See the shifts your friends are booking onboarding screen viewed`,
  CLICKED_WWF_SEE_FRIENDS_SHIFTS: `${workerMobilePrefix} See the shifts your friends are booking onboarding screen Enable Work with Friends clicked`,
  GRANTED_CONTACT_ACCESSS_SEE_FRIENDS_SHIFTS: `${workerMobilePrefix} See the shifts your friends are booking onboarding screen contact list access granted`,
};

const PERSONAL_INFO_EVENTS = [
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_FIRST_NAME,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LAST_NAME,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS,
];

const ONBOARDING_INCLUDE_EMAIL_PROPERTY_EVENTS = [
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LICENSE_INFORMATION,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL,
];

const DOCUMENT_EVENTS = [
  ONBOARDING_SEGMENT_EVENT_NAMES.ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP,
  ONBOARDING_SEGMENT_EVENT_NAMES.ADDED_DOCUMENT_DURING_SIGNUP,
];

export {
  ONBOARDING_SEGMENT_EVENT_NAMES,
  PERSONAL_INFO_EVENTS,
  ONBOARDING_INCLUDE_EMAIL_PROPERTY_EVENTS,
  DOCUMENT_EVENTS,
};
