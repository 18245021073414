import { differenceInSeconds, intervalToDuration } from "date-fns";
import { useCallback, useEffect, useState } from "react";

export const useCountdownTimer = (endTime): Duration => {
  const calculateTimeRemaining = useCallback(
    () => differenceInSeconds(endTime, new Date()),
    [endTime]
  );

  const [time, setTime] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = calculateTimeRemaining();
      setTime(newTime);

      if (newTime <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeRemaining]);

  return intervalToDuration({ start: 0, end: time * 1000 });
};
