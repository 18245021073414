import { logEvent } from "@src/appV2/lib/analytics";
import moment from "moment";

import { USER_EVENTS } from "../../../constants";
import {
  DOCUMENT_EVENTS,
  ONBOARDING_INCLUDE_EMAIL_PROPERTY_EVENTS,
  ONBOARDING_SEGMENT_EVENT_NAMES,
  PERSONAL_INFO_EVENTS,
} from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";

export interface OnboardingFunnelData {
  email?: string;
  firstName?: string;
  lastName?: string;
  preference_distance?: number;
  dob?: string;
  phone?: string;
  hcpId: string;
  hcpEventValue?: string;
  requirementId?: string;
  licenseType?: string;
  licenseExpiration?: string;
  licenseState?: string;
  licenseNumber?: string;
  didFeatureFlagGuessRegionCorrectly?: boolean;
}

export const fireOnboardingSegmentEvent = (eventName: string, data: OnboardingFunnelData): void => {
  try {
    const { hcpId } = data;

    const timestamp = moment().toISOString();

    const {
      hcpEventValue,
      requirementId,
      licenseType,
      licenseExpiration,
      licenseState,
      licenseNumber,
      email,
    } = data;

    logEvent(eventName, {
      hcpId,
      ...(PERSONAL_INFO_EVENTS.includes(eventName) ? { hcpEventValue } : {}),
      ...(ONBOARDING_INCLUDE_EMAIL_PROPERTY_EVENTS.includes(eventName) ? { email } : {}),
      ...(DOCUMENT_EVENTS.includes(eventName) ? { requirementId } : {}),
      ...(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LICENSE_INFORMATION
        ? { licenseType, licenseExpiration, licenseState, licenseNumber }
        : {}),
      timestamp,
    });
  } catch (error) {
    logEvent(USER_EVENTS.ONBOARDING_ERROR, {
      message: "Unable to fire segment log",
      eventName,
      agentId: data.hcpId,
    });
  }
};
