import { Preferences } from "@capacitor/preferences";
import { FC, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../store";
import { ONGOING_SHIFT_STORE_KEY, init } from "../store/ongoingShifts";

/**
 * FIXME, this is simply a hook and should be refactored to one.
 */
export const OngoingShiftSync: FC<{}> = () => {
  const dispatch = useDispatch();
  const ongoingShiftStore = useAppSelector((state) => state[ONGOING_SHIFT_STORE_KEY] ?? {});

  useEffect(() => {
    const updateStorageFromStore = async () => {
      /**
       * The default value here is true. It used to be an empty object.
       * It is pretty unusual...
       * FIXME - Revisit this odd code pattern.
       * Note also, we likely have race conditions here, related to the
       * `await Preferences.set` call.
       */
      const isLoading = ongoingShiftStore?.isLoading ?? true;

      if (!isLoading) {
        const storeData = JSON.stringify(ongoingShiftStore);
        await Preferences.set({
          key: ONGOING_SHIFT_STORE_KEY,
          value: storeData,
        });
      }
    };
    updateStorageFromStore();
  }, [ongoingShiftStore]);

  useEffect(() => {
    dispatch(init());
  }, [dispatch]);

  return <Fragment />;
};
